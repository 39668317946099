import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Img from "gatsby-image";

import { theme } from "../../../utils/global-styles/theme";

const OurStoryTimeline = ({ heading, desktopImage, mobileImage }) => {
  const TimelineGrid = styled.div`
      display: grid;
      grid-template: auto 1fr / 1fr;
      font-family: ${theme.fontFaces.headers};
      padding: 110px 14px 32px 14px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        display: none;
      }
    `,
    HeadingContainer = styled.div`
      grid-area: 1 / 1 / span 1 / span 1;
      padding-left: 120px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding-left: 0px;
      }
    `,
    TimelineHeading = styled.h1`
      font-size: 130px;
      font-weight: bold;
      letter-spacing: -6.25px;
      line-height: 130px;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 10vw;
        letter-spacing: -3.25px;
        line-height: 10vw;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 40px;
        letter-spacing: -1.92px;
        line-height: 40px;
      }
    `,
    TimelineImage = styled(Img)`
      grid-area: 2 / 1 / span 1 / span 1;
    `;

  return (
    <>
      {desktopImage && desktopImage.image && desktopImage.image.fluid && (
        <TimelineGrid
          css={css`
            @media only screen and (max-width: ${theme.breakpoints.helper800}) {
              display: none;
            }
          `}
        >
          <HeadingContainer>
            <TimelineHeading>{heading}</TimelineHeading>
          </HeadingContainer>
          <TimelineImage
            fluid={desktopImage.image.fluid}
            alt={desktopImage.imageDescription}
          />
        </TimelineGrid>
      )}
      {mobileImage && mobileImage.image && mobileImage.image.fluid && (
        <TimelineGrid
          css={css`
            display: none;

            @media only screen and (max-width: ${theme.breakpoints.helper800}) {
              display: grid;
              padding: 50px 14px;
            }
          `}
        >
          <HeadingContainer>
            <TimelineHeading>{heading}</TimelineHeading>
          </HeadingContainer>
          <TimelineImage
            fluid={mobileImage.image.fluid}
            alt={mobileImage.imageDescription}
          />
        </TimelineGrid>
      )}
    </>
  );
};

OurStoryTimeline.propTypes = {
  heading: PropTypes.string,
  desktopImage: PropTypes.shape({
    image: PropTypes.shape({
      fluid: PropTypes.shape({
        srcSet: PropTypes.string,
        aspectRatio: PropTypes.number,
      }),
    }),
    imageDescription: PropTypes.string,
  }),
  mobileImage: PropTypes.shape({
    image: PropTypes.shape({
      fluid: PropTypes.shape({
        srcSet: PropTypes.string,
        aspectRatio: PropTypes.number,
      }),
    }),
    imageDescription: PropTypes.string,
  }),
};

export default OurStoryTimeline;
