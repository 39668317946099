import React from "react";
import get from "lodash/get";

import OurStoryData from "../hooks/our-story-data";

import Layout from "../components/Layout/Layout";
import OurStoryHero from "../components/OurStory/child-components/OurStoryHero";
import FactBubbleSlider from "../components/BubbleSlider/BubbleSlider";
import Timeline from "../components/OurStory/child-components/Timeline";
import Manifesto from "../components/OurStory/child-components/Manifesto";

const OurStoryPage = () => {
  const storyData = OurStoryData();

  const findModuleByTypeAndTitle = (data, type, title) => {
    return data.contentModules.find(
      module =>
        module.__typename === type &&
        (type === "ContentfulTitleDescription"
          ? module.title === title
          : module.queryIdentifier === title)
    );
  };

  const manifesto = findModuleByTypeAndTitle(
    storyData,
    "ContentfulTitleDescription",
    "Our Manifesto"
  );
  const desktopTimeline = findModuleByTypeAndTitle(
    storyData,
    "ContentfulImage",
    "acumenAcademyTimeline"
  );
  const mobileTimeline = findModuleByTypeAndTitle(
    storyData,
    "ContentfulImage",
    "acumenAcademyMobileTimeline"
  );

  const heroImage = get(storyData, "contentModules[0]", null);
  const acumenBenefitsObject = get(storyData, "contentModules[1]", {});
  const ourStoryTitleAndDescription = get(storyData, "contentModules[2]", {});

  return (
    <Layout marketingModal={storyData.marketingModal}>
      <OurStoryHero
        heading={get(storyData, "heading", "A university re-imagined")}
        heroImage={heroImage}
        acumenBenefitsObject={acumenBenefitsObject}
        ourStoryTitleAndDescription={ourStoryTitleAndDescription}
      />
      <FactBubbleSlider
        slides={get(storyData, "contentModules[3].carouselItems", [])}
      />
      {desktopTimeline && mobileTimeline && (
        <Timeline
          heading="Timeline"
          desktopImage={desktopTimeline}
          mobileImage={mobileTimeline}
        />
      )}
      <Manifesto
        title={manifesto?.title}
        description={manifesto?.description?.description}
      />
    </Layout>
  );
};

export default OurStoryPage;
