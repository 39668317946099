import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Markdown from "markdown-to-jsx";
import get from "lodash/get";

import { theme } from "../../../utils/global-styles/theme";

import AcumenAcademyLogo from "../../shared/LargeLogo";

const OurManifesto = ({ title, description }) => {
  const ManifestoGrid = styled.div`
      display: grid;
      grid-template: auto / 342px 1fr 342px;
      font-family: ${theme.fontFaces.headers};

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        grid-template: auto / 18% 1fr 18%;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-template: auto / 40px 1fr 40px;
      }
    `,
    TitleDescriptionContainer = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      padding: 112px 0px 142px 0px;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        padding: 112px 0px 112px 0px;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 50px 0px 20px 0px;
      }
    `,
    Title = styled.h2`
      font-size: 53px;
      font-weight: bold;
      letter-spacing: -1.38px;
      line-height: 64px;
      margin-bottom: 38px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 36px;
        letter-spacing: -0.94px;
        line-height: 50px;
      }
    `,
    AALogoContainer = styled.div`
      grid-area: 1 / 3 / span 1 / span 1;
      display: flex;
      align-items: flex-end;
      margin-bottom: -140px;
      margin-left: -20px;
      z-index: 3;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        display: none;
      }
    `;

  return (
    <ManifestoGrid className="secondary-bg">
      <TitleDescriptionContainer>
        <Title>{title}</Title>
        <Markdown
          options={{
            overrides: {
              strong: {
                props: {
                  className: "manifesto-description-strong",
                },
              },
              p: {
                props: {
                  className: "manifesto-description",
                },
              },
            },
          }}
        >
          {description}
        </Markdown>
      </TitleDescriptionContainer>
    </ManifestoGrid>
  );
};

OurManifesto.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default OurManifesto;
