import { graphql, useStaticQuery } from "gatsby";

const OurStoryData = () => {
  const data = useStaticQuery(graphql`
    query {
      ourStory: allContentfulPageLayout(
        filter: {
          queryIdentifier: { eq: "Our Story" }
          node_locale: { eq: "en-US" }
        }
      ) {
        nodes {
          heading
          contentModules {
            ... on ContentfulImage {
              queryIdentifier
              image {
                fluid {
                  aspectRatio
                  srcSet
                }
              }
              imageDescription: description
            }
            ... on ContentfulBenefitsCard {
              benefit1
              description1
              benefit2
              description2
              benefit3
              description3
            }
            ... on ContentfulTitleDescription {
              title
              description {
                description
              }
            }
            ... on ContentfulCarouselContent {
              carouselItems {
                ... on ContentfulTitleDescription {
                  name
                  factsHighlight
                  description {
                    description
                  }
                  image {
                    fluid {
                      aspectRatio
                      srcSet
                    }
                  }
                }
              }
            }
          }
          marketingModal {
            ... on ContentfulMarketingModal {
              id
              cta {
                linkText
                url
              }
              description {
                description
              }
              title
              showAfterPercent
              hubspotListId
              marketingModalImage {
                image {
                  fluid {
                    aspectRatio
                    srcSet
                  }
                }
              }
              formFields
              successTitle
              successDescription
              additionalText {
                json
              }
            }
          }
        }
      }
    }
  `);
  return data.ourStory.nodes[0];
};

export default OurStoryData;
